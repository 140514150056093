import { COLORS } from "../styles/colors";

export const Footer = () => {
  return (
    <>
      <div
        style={{ height: "10px", width: "100%", backgroundColor: "black" }}
      />
      <div style={{ height: "4px", width: "100%", backgroundColor: "white" }} />
      <div
        style={{ height: "10px", width: "100%", backgroundColor: "black" }}
      />
      <div
        className="footer"
        style={{ backgroundColor: COLORS.white, fontSize: "1.5rem" }}
      >
        <div className="footer-column">
          <h4>Calvary Chapel Jackson County</h4>
          <p>
            1201 S Broadway, Unit C,
            <br />
            Oak Grove, MO 64075
          </p>
        </div>
        <div className="footer-column">
          <h4>Service Times:</h4>
          <p>
            Sunday Morning Prayer - 9:00AM
            <br />
            Sunday Morning Worship Service - 10:00AM
            <br />
            Wednesday Evening Family Service - 7:00PM
          </p>
        </div>
        <div className="footer-column">
          <h4>Contact us:</h4>
          <p style={{ overflowWrap: "anywhere" }}>contact@ccjacksoncounty.com</p>
        </div>
      </div>
    </>
  );
};
