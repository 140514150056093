import { createBrowserRouter } from "react-router-dom";

import { Home } from "./routes/Home";
import { ErrorPage } from "./routes/Error";
import { Missions } from "./routes/Missions";
import { MissionAndVision } from "./routes/About/MissionAndVision";
import { Services } from "./routes/About/Services";
import { StatementOfFaith } from "./routes/About/StatementOfFaith";
import { Salvation } from "./routes/KnowGod/Salvation";
import { Study } from "./routes/KnowGod/Study";
import { Leadership } from "./routes/About/Leadership";
import { PageContainer } from "./components/PageContainer";

export default createBrowserRouter([
  {
    path: "/",
    element: <PageContainer />,
    children: [
      { path: "", element: <Home /> },
      {
        path: "mission-and-vision",
        element: <MissionAndVision />,
      },
      {
        path: "services",
        element: <Services />,
      },
      {
        path: "statement-of-faith",
        element: <StatementOfFaith />,
      },
      {
        path: "leadership",
        element: <Leadership />,
      },
      {
        path: "salvation",
        element: <Salvation />,
      },
      {
        path: "study",
        element: <Study />,
      },
      {
        path: "missions",
        element: <Missions />,
      },
    ],
    errorElement: <ErrorPage />,
  },
]);
