import React, { useRef, useState, useCallback, useId } from "react";
import { MenuItem, Menu, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export const NavItemLabel = ({ label }) => {
  return (
    <Typography
      variant="h4"
      noWrap
      color={"primary"}
      sx={{
        mr: 1,
        display: "flex",
        flexGrow: 1,
        fontWeight: 700,
        fontFamily: ["Georgia", "Times", "Times New Roman", "serif"],
        letterSpacing: ".1rem",
        textDecoration: "none",
        fontSize: "1.75em",
      }}
    >
      {label}
    </Typography>
  );
};

const MoreMenuItem = ({ label, children, id, MenuProps, ...other }) => {
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  const menuItemRef = useRef(null);
  const menuItemId = useId();
  const normMenuItemId = id ?? menuItemId;

  const handleItemKeyDown = (ev) => {
    if (
      ev.key !== "ArrowRight" ||
      ev.ctrlKey ||
      ev.shiftKey ||
      ev.altKey ||
      ev.metaKey
    )
      return;
    ev.preventDefault();
    ev.stopPropagation();
    setIsOpen(true);
  };

  const handleMenuKeyDown = (ev) => {
    ev.stopPropagation();
    if (
      (ev.key !== "ArrowLeft" && ev.key !== "Escape") ||
      ev.ctrlKey ||
      ev.shiftKey ||
      ev.altKey ||
      ev.metaKey
    )
      return;
    ev.preventDefault();
    setIsOpen(false);
  };

  return (
    <MenuItem
      {...other}
      onKeyDown={handleItemKeyDown}
      ref={menuItemRef}
      color={"primary"}
      sx={[isOpen && { backgroundColor: "action.hover" }]}
      onMouseEnter={open}
      onMouseLeave={close}
      id={normMenuItemId}
    >
      <NavItemLabel label={label} />
      <NavigateNextIcon color={"primary"} sx={{ fontSize: "2rem" }} />
      <Menu
        TransitionProps={{ onExited: () => menuItemRef.current?.focus() }} // If you need a submenu more than one level deep, it's probably better to use context to check if it's a top level component to focus only once
        disableRestoreFocus
        onKeyDown={handleMenuKeyDown}
        sx={{
          pointerEvents: "none",
          "& .MuiList-root": {
            pointerEvents: "auto",
          },
        }}
        MenuListProps={{
          ...MenuProps?.MenuListProps,
          "aria-labelledby": normMenuItemId,
        }}
        anchorEl={menuItemRef.current}
        open={isOpen}
        onClose={close}
        anchorOrigin={
          MenuProps?.anchorOrigin ?? { vertical: "center", horizontal: "right" }
        }
        transformOrigin={
          MenuProps?.transformOrigin ?? {
            vertical: "center",
            horizontal: "left",
          }
        }
      >
        {children}
      </Menu>
    </MenuItem>
  );
};

export default MoreMenuItem;
