import { COLORS } from "../../styles/colors";

export const Services = () => {
  return (
    <div
      style={{
        backgroundColor: COLORS.neutralDark,
        color: COLORS.neutralLight,
      }}
    >
      <div
        className="section-image"
        style={{
          backgroundImage: `url(${"hands-raised.jpg"})`,
        }}
      >
        <h1>Services</h1>
      </div>
      <div className={"info-container"}>
        <h2>When?</h2>
        <p>Sunday Group Prayer @ 9:00AM</p>
        <p>Sunday Worship Service @ 10:00AM</p>
        <p>Wednesday Family Service @ 7:00PM</p>
        <h2>Where?</h2>
        <p>1201 S Broadway, Unit C, Oak Grove, MO 64075</p>
        <br />
        <p>
          Parking for handicapped/elderly behind building, both sides of
          Broadway in front of building, and across 12th St from the rear
          parking lot there is a parking lot.
        </p>
        <h2>What to Expect</h2>
        <p>
          On Sunday mornings we have a time of worship together, and a time of
          teaching the Word. Services usually last around an hour. Kids 12 and
          under are free to go to Children's Church during the teaching. We also
          have a room for nursing mothers to hear the teaching and have some
          privacy.
        </p>
        <p>Wednesday evenings are in the same format as Sundays</p>
        <p>
          We have coffee and other snacks before services on both Sundays and
          Wednesdays.
        </p>
      </div>
    </div>
  );
};
