import { Section } from "../components/Section";
import { COLORS } from "../styles/colors";

export const Missions = () => {
  return (
    <>
      <Section
        backgroundColor={COLORS.primaryLight}
        textColor={COLORS.primaryDark}
        title={"Missions"}
      />
    </>
  );
};
