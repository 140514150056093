import { COLORS } from "../../styles/colors";

export const MissionAndVision = () => {
  return (
    <div
      style={{
        backgroundColor: COLORS.neutralDark,
        color: COLORS.neutralLight,
      }}
    >
      <div
        className="section-image"
        style={{
          backgroundImage: `url(${"sunrise-field.jpg"})`,
        }}
      >
        <h1>Mission and Vision</h1>
      </div>
      <div className={"info-container"}>
        <h2>Mission</h2>
        <p>
          Because “the word of God <i>is</i> living and powerful, and sharper
          than any two-edged sword, piercing even to the division of soul and
          spirit, and of joints and marrow, and is a discerner of the thoughts
          and intents of the heart,” our Mission is to make the Word central to
          everything we do as believers.
        </p>
        <br />
        <p>
          The Word is central to reaching the lost:{" "}
          <i>
            “So then faith <i>comes</i> by hearing, and hearing by the word of
            God” - Romans 10:17
          </i>
        </p>
        <br />
        <p>
          The Word is central to sanctification (that is, becoming more like
          Christ and less like ourselves):{" "}
          <i>
            “[Jesus prayed] Sanctify them by Your truth. Your word is truth” -
            John 17:17
          </i>
        </p>
        <br />
        <p>
          Therefore, our Mission is to simply teach the Word simply -
          verse-by-verse, chapter-by-chapter, precept-upon-precept.
        </p>
        <h2>Vision</h2>
        <p>
          In the teaching of the Word, that the lost might be found, the deaf
          would hear, and that the blind would see.
        </p>
        <br />
        <p>
          For the believer, that the individual would be “thoroughly equipped
          for the work of ministry, for the edifying of the body of Christ, till
          we all come to the unity of the faith and of the knowledge of the Son
          of God, to a perfect man, to the measure of the stature of the
          fullness of Christ; that we should no longer be children, tossed to
          and fro and carried about with every wind of doctrine, by the trickery
          of men, in the cunning craftiness of deceitful plotting, but, speaking
          the truth in love, may grow up in all things into Him who is the
          head—Christ— from whom the whole body, joined and knit together by
          what every joint supplies, according to the effective working by which
          every part does its share, causes growth of the body for the edifying
          of itself in love” (Ephesians 4:12-16).
        </p>
      </div>
    </div>
  );
};
