import { Outlet } from "react-router-dom";
import "../routes/Home";
import { CompoundNavBar } from "./CompoundNavBar";
import { Footer } from "./Footer";
import { useLocation } from "react-router-dom";
import { HomePageVideo } from "./HomePageVideo";

export const PageContainer = () => {
  const location = useLocation();
  console.log(location.pathname);
  return (
    <div className="App">
      {
        //location.pathname === "/" && <HomePageVideo />
      }
      <CompoundNavBar />
      <Outlet />
      <Footer />
    </div>
  );
};
