import { COLORS } from "../../styles/colors";

export const Leadership = () => {
  return (
    <div
      style={{
        backgroundColor: COLORS.neutralLight,
        color: COLORS.neutralDark,
      }}
    >
      <div
        className="section-image"
        style={{
          color: COLORS.neutralLight,
          backgroundImage: `url(${"walking-with-bible.jpg"})`,
        }}
      >
        <h1>Leadership</h1>
      </div>
      <div
        style={{
          display: "flex",
          padding: "80px",
        }}
      >
        <div style={{ padding: "0 40px" }}>
          <div
            style={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              height: "400px",
              width: "300px",
              backgroundImage: `url(${"bible-and-compass.png"})`,
            }}
          />
          <p>Pastor Josh Mullins and his wife, Alea</p>
        </div>
        <div>
          <p>
            Josh and Alea met in college in August, 2003, were married in May,
            2005, and spent over 15 years attending and serving at Calvary
            Chapel Maryville, in Maryville, MO. In December, 2018 Josh and Alea
            felt God was leading them to the Kansas City area, specifically
            around Blue Springs, to start a fellowship. Once they moved, Josh
            started holding a Bible Study in the Blue Springs Hobby Lobby craft
            classroom on Thursday nights while attending both Calvary Chapel
            Kansas City (in Leawood, KS) and Calvary Chapel Liberty (in Liberty,
            MO). This study moved twice, once to a park pavilion, and later to
            Josh and Alea's home. Eventually, a second Bible Study started to be
            held on Sunday evenings in North Kansas City, in a friend's home.
            Josh felt led to start holding a Sunday morning service in his home.
            Having been ordained in January 2021 at Calvary Chapel St. Joseph
            (in St. Joseph, MO) by Pastor Chuck Rhein, Josh officially started
            Calvary Chapel Jackson County.
          </p>
          <p>
            Josh teaches both in Sunday morning services and the Wednesday
            evening family services. He also currently leads worship for the
            fellowship.
          </p>
          <p>
            Alea, apart from being Josh's biggest supporter, teaches our
            Children's Church on Sunday mornings, bakes all sorts of snacks for
            services, and helps lead worship.
          </p>
        </div>
      </div>
    </div>
  );
};
