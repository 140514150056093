import { useRouteError } from "react-router-dom";
import { Section } from "../components/Section";
import { COLORS } from "../styles/colors";

export const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  const text = (
    <div>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );

  return (
    <>
      <Section
        backgroundColor={COLORS.primaryLight}
        textColor={COLORS.primaryDark}
        title={text}
      />
    </>
  );
};
