import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from "react-router-dom";

import Logo from '../content/logo.webp';
import MoreMenuItem, { NavItemLabel } from './MoreMenuItem';

const renderDesktopMenuItems = (onCloseHandler = () => { }) => <>
  {/* <MoreMenuItem key='about' label="About"> */}
  <MenuItem component={Link} to={'mission-and-vision'} onClick={onCloseHandler}><NavItemLabel label='Mission and Vision' /></MenuItem>
  <MenuItem component={Link} to={'services'} onClick={onCloseHandler}><NavItemLabel label='Services' /></MenuItem>
  <MenuItem component={Link} to={'statement-of-faith'} onClick={onCloseHandler}><NavItemLabel label='Statement of Faith' /></MenuItem>
  {/* <MenuItem component={Link} to={'leadership'} onClick={onCloseHandler}><NavItemLabel label='Leadership' /></MenuItem> */}
  {/* </MoreMenuItem> */}
  {/* <MoreMenuItem key='know-God' label="Know God">
    <MenuItem component={Link} to={'salvation'} onClick={onCloseHandler}><NavItemLabel label='Salvation' /></MenuItem>
    <MenuItem component={Link} to={'study'} onClick={onCloseHandler}><NavItemLabel label='Study' /></MenuItem>
  </MoreMenuItem>
  <MenuItem key='missions' onClick={onCloseHandler}><NavItemLabel label='Missions' /></MenuItem> */}
</>

const renderMobileMenuItems = (onCloseHandler = () => { }) => (<>
  {/* <div className="mobile-nav-group-header"><NavItemLabel label='About' /></div> */}
  <div style={{ marginLeft: '2rem' }}>
    <MenuItem component={Link} to={'mission-and-vision'} onClick={onCloseHandler}><NavItemLabel label='Mission and Vision' /></MenuItem>
    <MenuItem component={Link} to={'services'} onClick={onCloseHandler}><NavItemLabel label='Services' /></MenuItem>
    <MenuItem component={Link} to={'statement-of-faith'} onClick={onCloseHandler}><NavItemLabel label='Statement of Faith' /></MenuItem>
    {/* <MenuItem component={Link} to={'leadership'} onClick={onCloseHandler}><NavItemLabel label='Leadership' /></MenuItem> */}
  </div>
  {/* <div className="mobile-nav-group-header"><NavItemLabel label='Know God' /></div>
  <div style={{ marginLeft: '3rem' }}>
    <MenuItem component={Link} to={'salvation'} onClick={onCloseHandler}><NavItemLabel label='Salvation' /></MenuItem>
    <MenuItem component={Link} to={'study'} onClick={onCloseHandler}><NavItemLabel label='Study' /></MenuItem>
  </div>
  <div className="mobile-nav-group-header"><NavItemLabel label='Missions' /></div> */}
</>
)

export const CompoundNavBar = () => {
  const [open, setOpen] = useState(false);
  const isDesktop = useMediaQuery('(min-width:1100px)');

  const handleClick = () => {
    // close if menu is already open
    if (open) handleClose()
    else setOpen(true)
  };

  const handleClose = () => {
    setOpen(false)
  };

  return (
    <Accordion expanded={!isDesktop && open} onClick={handleClick} classes={{ expanded: 'expanded-nav-container' }}>
      <AppBar position="sticky" sx={{ top: '0px', backgroundColor: "white" }}>
        <Toolbar disableGutters>
          <Link to='/' >
            <img src={Logo} alt='CCJC Logo' sx={{ display: 'flex', mr: 4, ml: 4 }} />
          </Link> {/* Menu for mobile */}

          {isDesktop ? <Box sx={{ flexGrow: 1, display: 'flex' }}>
            {renderDesktopMenuItems()}
          </Box> : <Box sx={{ flexGrow: 1, display: 'flex' }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleClick}
              color="primary"
            >
              <MenuIcon />
            </IconButton>
          </Box>}
        </Toolbar>
      </AppBar >
      {!isDesktop && renderMobileMenuItems()}

    </Accordion>
  );
}
