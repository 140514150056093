import { RouterProvider } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import router from "./router";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Cambria",
      "Cochin",
      "Georgia",
      "Times",
      '"Times New Roman"',
      "serif",
    ].join(","),
  },
  palette: {
    primary: {
      main: "#365e61",
    },
    secondary: {
      main: "#686c6d",
    },
  },
});

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};
