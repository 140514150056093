import "./Home.css";
import { COLORS } from "../styles/colors";

export const Home = () => {
  return (
    <div>
      <div
        className="section-image"
        style={{
          backgroundImage: `url(${"bible-pages-open.jpg"})`,
        }}
      >
        <h2 style={{ padding: "3vw", color: "white" }}>
          Calvary Chapel Jackson County
        </h2>
      </div>
      <div
        style={{
          color: "white",
          padding: "2rem 0",
          letterSpacing: ".5rem",
          fontSize: "5vw",
          backgroundColor: COLORS.neutralMedium,
          fontStyle: "oblique",
        }}
      >
        <div style={{ padding: ".5rem 0" }}>Verse - by - Verse</div>
        <div style={{ padding: ".5rem 0" }}>Chapter - by - Chapter</div>
        <div style={{ padding: ".5rem 0" }}>Line - by - Line</div>
        <div style={{ padding: ".5rem 0" }}>Precept - upon - Precept</div>
      </div>
    </div>
  );
};
