import { COLORS } from "../../styles/colors";

export const Study = () => {
  return (
    <div
      style={{
        backgroundColor: COLORS.neutralDark,
        color: COLORS.neutralLight,
      }}
    >
      <div
        className="section-image"
        style={{
          backgroundImage: `url(${"bible-and-compass.png"})`,
        }}
      >
        <h1>Statement of Faith</h1>
      </div>
      <div
        style={{
          padding: "80px",
          backgroundColor: COLORS.primaryMedium,
        }}
      >
        <h2>THE SCRIPTURES</h2>
        <p>
          We believe that the scriptures of the Old and New Testaments, in their
          original autograph, are the Word of God, fully inspired without error
          and the infallible rule of faith and practice. The Word of God is the
          foundation upon which the church operates and is the basis for which
          the church is governed. We believe that the Word of God supersedes any
          earthly law that is contrary to the Holy Scriptures{" "}
          <i>
            (Isaiah 28:13; Nehemiah 8:8; John 17:17; II Timothy 3:16-17; Hebrews
            4:12; I Peter 1:23-25; II Peter 1:3-4, 21).
          </i>
        </p>
      </div>
      <div
        style={{
          padding: "80px",
          backgroundColor: COLORS.primaryLight,
        }}
      >
        <h2>THE TRINITY</h2>
        <p>
          We believe that there is one living and true GOD, eternally existing
          in three persons: The Father, the Son, and the Holy Spirit, equal in
          power and glory; that this triune God created all, upholds all, and
          governs all things{" "}
          <i>
            (Genesis 1:1; Deuteronomy 6:4; Isaiah 44:8, 48:16; Matthew 28:19-20;
            John 10:30; Hebrews 1:3).
          </i>
        </p>
        <div
          style={{
            padding: "40px",
            backgroundColor: COLORS.primaryDark,
          }}
        >
          <div>
            <h3>GOD THE FATHER</h3>
            <p>
              We believe in the person of God the Father, an infinite, eternal,
              personal Spirit, perfect in holiness, wisdom, power and love; that
              He concerns Himself mercifully in the affairs of men; that He
              hears and answers prayer; and that He saves from sin and death all
              those who come to Him through Jesus Christ{" "}
              <i>
                (Deuteronomy 33:27; Psalm 90:2, 102:27; John 3:16, 4:24; I
                Timothy 1:17; Titus 1:3).
              </i>
            </p>
          </div>
          <div>
            <h3>GOD THE SON</h3>
            <p>
              We believe in the person of Jesus Christ, God's only begotten Son,
              conceived by the Holy Spirit. We believe in His virgin birth,
              sinless life, miracles and teachings, his substitutionary atoning
              death, bodily resurrection, ascension into heaven, perpetual
              intercession for His people and personal, visible return to earth{" "}
              <i>
                (Isaiah 7:14; Micah 5:2; Matthew 1:23; Mark 16:19; Luke 1:34-35;
                John 1:1-2, 8:58,11:25; I Corinthians 15:3-4; I Timothy 3:16;
                Hebrews 1:8; I John 1:2; Revelation 1:8).
              </i>
            </p>
          </div>
          <div>
            <h3>GOD THE HOLY SPIRIT</h3>
            <p>
              We believe in the person of the Holy Spirit, Who came forth from
              the Father and Son to convict the world of sin, righteousness, and
              judgment, and to regenerate, sanctify and empower for ministry all
              who believe in Christ{" "}
              <i>
                (John 16:8-11; Acts 1:8; Romans 8:26, 15:13,16; II Corinthians
                3:18; Hebrews 9:14).
              </i>{" "}
              We believe the Holy Spirit indwells every believer in Jesus Christ
              and that He is an abiding helper, teacher, and guide{" "}
              <i>(John 16:13, 14:16-17, 16:8-11; Romans 8:26).</i> We believe in
              the Baptism of the Holy Spirit as a distinct and separate
              experience to that of regeneration, occurring either subsequent to
              or simultaneous with salvation, evidenced by a greater dynamic in
              the Christian's life, enabling that person to be a bold and more
              effective witness. The supreme evidence of the Spirit-filled life
              is the fruit of the Spirit, love. We believe in the present day
              ministry of the Holy Spirit in regard to the exercise of all
              biblical gifts of the Holy Spirit according to the instructions
              given to us <i>(I Corinthians 12-14).</i>
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          padding: "80px",
          backgroundColor: COLORS.primaryMedium,
        }}
      >
        <h2>SIN/SALVATION</h2>
        <p>
          We believe that all people are sinners by nature and choice, falling
          short of God's standard and breaking His commandments – and therefore
          are under God's righteous judgment. Yet, God saves and gives new life
          to all who come to Him in repentance and faith, trusting in the Person
          and work of Jesus Christ. At the cross, Jesus Christ died in our place
          as our substitute, absorbing God's wrath that should have come upon
          us. Because Jesus died for the sins of the world the invitation to
          believe is open to all, and whoever desires may come unto Jesus for
          new life and the forgiveness of sins{" "}
          <i>
            (Matthew 11:28; John 3:16; Acts 3:19, 20:21; Romans 3:23; Ephesians
            2:1-3, 8-9; I John 2:2; Revelation 22:17).
          </i>
        </p>
      </div>
      <div
        style={{
          padding: "80px",
          backgroundColor: COLORS.primaryLight,
        }}
      >
        <h2>MALE LEADERSHIP IN THE CHURCH</h2>
        <p>
          We believe in the pattern and principle of male leadership and
          responsibility in both the home and the church, according to the
          sacrificial example of Jesus. We believe this limits the roles of
          pastoral leadership and doctrinal authority to qualified men{" "}
          <i>(I Corinthians 11:1-12; I Timothy 2:1-15).</i> We believe in the
          universal church, the living spiritual body, of which Christ is the
          head and all who are born again are a part of the Body of Christ{" "}
          <i>(I Corinthians 12:12-13; Ephesians 4:15-16).</i>
        </p>
      </div>
      <div
        style={{
          padding: "80px",
          backgroundColor: COLORS.primaryMedium,
        }}
      >
        <h2>ORDINANCES</h2>
        <p>
          We believe that the Lord Jesus Christ instituted two ordinances for
          the church: the full-immersion water baptism of believers and the
          Lord's Supper{" "}
          <i>
            (Matthew 28:19; Luke 22:19-20; Acts 2:38; I Corinthians 11:23-26).
          </i>
        </p>
      </div>
      <div
        style={{
          padding: "80px",
          backgroundColor: COLORS.primaryLight,
        }}
      >
        <h2>HEAVEN AND HELL</h2>
        <p>
          We believe in a literal Heaven and a literal Hell and that all those
          who place their faith, hope and trust in Jesus Christ will spend
          eternity in Heaven with the Lord. Those who reject Jesus' free gift of
          salvation will spend eternity separated from the Lord{" "}
          <i>
            (Psalm 9:17; Matthew 5:3, 5:22, 18:9, 25:31-34; Mark 9:42-49; Luke
            12:5; John 3:18; Hebrews 12:23; I Peter 1:4; Revelation 14:10-11,
            20:11-15).
          </i>
        </p>
      </div>
      <div
        style={{
          padding: "80px",
          backgroundColor: COLORS.primaryMedium,
        }}
      >
        <h2>CHRIST'S SECOND COMING</h2>
        <p>
          We believe in the Second Coming of Jesus Christ, which is His
          personal, visible return to Earth and the establishment of His
          millennial kingdom, in the resurrection of the body, the final
          judgment and eternal blessing of the righteous and endless separation
          of the wicked{" "}
          <i>(Matthew 16:27; Acts 1:11; Revelation 19:11-16, 20:11-15).</i> We
          believe in the Pre-Tribulation Rapture of the Church where all
          believers will meet the Lord in the air and be taken out of this world
          prior to the Tribulation that will come upon the earth{" "}
          <i>
            (Isaiah 26:20; Matthew 24:29-31; Luke 21:36; Romans 1:18, 5:9; I
            Thessalonians 1:10, 4:13-16, 5:9; II Peter 2:7-9; Revelation 3:10,
            5:7-10, 7:13-14).
          </i>
        </p>
      </div>
      <div
        style={{
          padding: "80px",
          backgroundColor: COLORS.primaryLight,
        }}
      >
        <h2>MARRIAGE</h2>
        <p>
          We believe that God created man and that He created them male and
          female. As such, He created them different so as to complement and
          complete each other. God instituted monogamous marriage between male
          and female as the foundation of the family and the basic structure of
          human society. Therefore, we perform marriages in accordance with the
          Bible consistent with the Old and New Testament{" "}
          <i>
            (Genesis 2:24; Matthew 19:4-6; John 4:16-18; Romans 1:18-32; I
            Corinthians 5:11, 6:9-11,18-20, 7:1-3,8-9; Galatians 5:19-21;
            Ephesians 5:3-7; I Timothy 1:9-11).
          </i>
        </p>
      </div>
    </div>
  );
};
